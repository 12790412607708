import Amplify, { Auth, API, graphqlOperation, Storage } from "aws-amplify"
import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  Greetings,
  SignUp,
  ForgotPassword,
} from "aws-amplify-react"
import CustomSignUp from "../../../components/CustomSignUp"
import {
  createProject,
  updateProject,
  createRoom,
} from "../../../graphql/mutations"

import { Link, NavLink } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import awsExports from "../../../aws-exports"
import { Hub } from "aws-amplify"
import { listProjects } from "../../../graphql/queries"
import { deleteProject } from "../../../graphql/mutations"
import { onUpdateRoom } from "../../../graphql/subscriptions"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import "./index.scss"
import AdminMenu from "../../../components/AdminMenu"
// import Edit from "../edit/index"

import axios from "axios"

Amplify.configure(awsExports)

const SignupLink = props => (
  <div>
    {/* {props.authState} */}
    {props.authState === "signIn" && (
      <div>
        <button onClick={() => props.onStateChange("signUp")}>
          Sign Up here
        </button>
      </div>
    )}
  </div>
)

const AdminPage = () => {
  const [user, setUser] = useState(null)
  const [userPool, setUserPool] = useState(null)
  const [projects, setProjects] = useState([])
  const [roomCount, setRoomCount] = useState(1)
  useEffect(() => {
    if (!user) {
      getUser()
    }
    fetchProjects()
    const subscription = API.graphql(graphqlOperation(onUpdateRoom)).subscribe({
      next: room => console.log("room update!: ", room),
    })
    console.log("roomcount: ", roomCount)
  }, [])

  async function getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      console.log("user: ", user)
      //check for user pool group
      console.log(
        "User Pool Group: ",
        user.signInUserSession.accessToken.payload["cognito:groups"]
      )
      // console.log("attributes: ", user.attributes)
      console.log(
        "Is Admin: ",
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "Admins"
        )
      )
      setUser(user)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  Hub.listen("auth", data => {
    switch (data.payload.event) {
      case "signIn":
        console.log("user signed in")
        getUser()
        break
      case "signUp":
        console.log("user signed up")
        break
      case "signOut":
        console.log("user signed out")
        break
      case "signIn_failure":
        console.log("user sign in failed")
        break
      case "configured":
        console.log("the Auth module is configured")
    }
  })

  async function cloneRoom(id, project_name, copies) {
    const response = await API.get("createRoomForScene", "/room", {
      queryStringParameters: {
        sceneId: id,
        projectId: project_name,
        copies: copies,
      },
    })
    console.log("response: ", response)
    addRoom(response.body)
  }

  async function addRoom(roomsArray) {
    try {
      roomsArray.map(async room => {
        const finalRoom = {
          projectID: room.projectId,
          hubsId: room.hubsId,
          userCountLobby: 0,
          userCountRoom: 0,
        }
        const submittedRoom = await API.graphql(
          graphqlOperation(createRoom, { input: finalRoom })
        )
        console.log("Just created this room: ", submittedRoom)
      })
    } catch (err) {
      console.log("error creating room:", err)
    }
  }

  async function fetchProjects() {
    try {
      const projectData = await API.graphql(
        graphqlOperation(listProjects, {
          limit: 1000,
        })
      )
      const projects = projectData.data.listProjects.items
      setProjects(projects)
      console.log("projects: ", projects)
    } catch (err) {
      console.log("error fetching projects: ", err)
    }
  }

  function handleAuthStateChange(state) {
    if (state === "signedIn") {
      console.log("running handle auth change")
      getUser()
    }
  }

  const ProjectList = props => {
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
  
  
    // console.log("auth-state: ", props.authState)
    // console.log("auth-data: ", props.authData)
    if (
      props.authState === "signedIn" &&
      user &&
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Admins"
      )
    ) {
      if (projects.length > 0) {
        return (
          <div>
            <AdminMenu />
            <section>
              {projects.map((project, index) => (
                <div
                  key={project.id ? project.id : index}
                  style={{
                    border: "1px solid black",
                    margin: "1rem",
                  }}
                >
                  <ul>
                    <li key="id">ID: {project.id}</li>
                    <li key="title">Title: {project.title}</li>
                    <li key="abstract">Abstract: {project.description}</li>
                    <li key="scene-id">Scene ID: {project.sceneUrl}</li>
                    <li key="scene-id">Image name: {project.image}</li>

                    <li key="rooms">
                      Rooms:
                      <ul>
                        {project.rooms.items &&
                          project.rooms.items.map(room => (
                            <li key={room.id}>Hubs ID: {room.hubsId}</li>
                          ))}
                      </ul>
                    </li>
                  </ul>
                  <div>
                    <input
                      style={{
                        width: "3rem",
                      }}
                      width="2"
                      onChange={event => setRoomCount(event.target.value)}
                      value={roomCount}
                      type="number"
                      size="2"
                      maxLength="2"
                    />
                    <button
                      onClick={() =>
                        cloneRoom(project.sceneUrl, project.id, roomCount)
                      }
                    >
                      Create Room(s) from Scene ID
                    </button>
                  </div>
                  <div>
                    <button onClick={() => deleteThisProject(project)}>
                      Delete
                    </button>
                    {/* <button onClick={handleShow}>Edit</button> */}
                  </div>
                </div>
              ))}
            </section>
          </div>
        )
      } else {
        return <Link to="/partner/submit">Submit a project</Link>
      }
    } else return <div>You don't have permission to view this page. </div>
  }

  async function deleteThisProject(inputProject) {
    try {
      if (inputProject.tags.items.length > 0) {
        alert("You first need to remove all tags from this project.")
      } else {
        const project = { id: inputProject.id }
        const deletedProject = await API.graphql(
          graphqlOperation(deleteProject, { input: project })
        )
        console.log("deleted project: ", deletedProject)
        // const newProjectState = projects.filter(project => project.)
        fetchProjects()
      }
    } catch (err) {
      console.log("error deleting project:", err)
    }
  }

  return (
    <section>
      <div className="authenticator">
        <Authenticator
          hideDefault={true}
          onStateChange={handleAuthStateChange()}
        >
          <ProjectList />

          <SignIn />
          {/* <CustomSignUp /> */}
          {/* <SignUp signUpConfig={mySignUpConfig} /> */}
          <CustomSignUp />
          <ConfirmSignUp />
          <ForgotPassword />
          <SignupLink />
        </Authenticator>
      </div>
      <SEO title="Admin Dashboard" />
      <Link to="/">Go back to the homepage</Link>
    </section>
  )
}

export default AdminPage
